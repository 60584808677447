/* eslint-disable no-unused-vars */
import { RADIO_BUTTON_CHECKED, RADIO_BUTTON_UNCHECKED } from '@assets/icons'
import data from '@constants/permissionListData.json'

export default function PermissionList() {
  return (
    <div className="grid px-4 grid-cols-4 gap-7">
      {/* Headers */}
      {[
        { label: 'Permission' },
        { label: 'Trader' },
        { label: 'Manager' },
        { label: 'Admin' },
      ].map(({ label }, index) => (
        <div
          key={label}
          className={`col-span-1 font-bold ${
            index !== 0 ? 'grid justify-center' : ''
          }`}
        >
          <span>{label}</span>
        </div>
      ))}
      {/* Rows */}
      <div className="col-span-4 grid gap-2">
        {data?.map(({ label, users }) => (
          <div className="col-span-4 grid grid-cols-4 odd:bg-white even:bg-lightersmoke p-2 rounded-lg">
            <p className="col-span-1 text-base">{label}</p>
            {[0, 1, 2].map((el) => (
              <p className="col-span-1 justify-center grid">
                {users[el] ? (
                  <img
                    src={RADIO_BUTTON_CHECKED}
                    alt="checked"
                    className="w-7 h-7"
                  />
                ) : (
                  <img
                    src={RADIO_BUTTON_UNCHECKED}
                    alt="unchecked"
                    className="w-7 h-7"
                  />
                )}
              </p>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}
