import axiosClient from '@services/Axios'

export function fetchUsers(roleId) {
  let url = '/users'
  if (roleId !== undefined) url += `?role=${roleId}`

  return axiosClient.get(url)
}

export function fetchRoles() {
  return axiosClient.get(`/users/roles`)
}

export function updateUser(id, data) {
  return axiosClient.put(`/users/${id}`, data)
}

export function updateStatusUser(id, data) {
  return axiosClient.put(`/users/status/${id}`, data)
}

export async function createUser(data) {
  return axiosClient.post(`/users`, data)
}
