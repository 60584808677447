import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import { selectSessionToken } from '@selectors'
import {
  fetchEvents,
  fetchEventById,
  createEvent,
  createCompetition,
  fetchCompetitions,
  fetchBatsmen,
  changeEventVisibility,
  updateEvent,
  updateTeams,
  deleteMarkets,
  suspendMarkets,
  updateAnnouncement,
} from '@services/Events'

export function useEvents({ election, virtual, date, dateEnd, section }) {
  const token = useSelector(selectSessionToken)
  const fDate = format(date ?? new Date(), 'yyyy-MM-dd')
  const fEndDate = format(dateEnd ?? new Date(), 'yyyy-MM-dd')

  const config =
    section === 'overview' ? ['events_overview', fDate] : ['events', fDate]

  const events = useQuery(
    config,
    async () => {
      let fData

      if (election) {
        const {
          data: { result },
        } = await fetchEvents({ election, date: fDate })
        fData = result
      } else if (virtual) {
        const {
          data: { result },
        } = await fetchEvents({ virtual, date: fDate })
        fData = result
      } else {
        if (date && dateEnd) {
          if (fDate === fEndDate) {
            const {
              data: { result },
            } = await fetchEvents({ election, date: fDate })
            fData = result
          } else {
            const {
              data: { result },
            } = await fetchEvents({
              election,
              date: fDate,
              dateEnd: fEndDate,
            })
            fData = result
          }
        }

        if (date && !dateEnd) {
          const {
            data: { result },
          } = await fetchEvents({ election, date: fDate })
          fData = result
        }

        if (!date && !dateEnd) {
          const {
            data: { result },
          } = await fetchEvents()
          fData = result
        }
      }

      return fData
    },
    {
      refetchOnWindowFocus: false,
      retry: 0,
      refetchOnMount: true,
      enabled: !!token,
    },
  )

  return events
}

export function useEventById(id, enabled) {
  const event = useQuery(
    'event',
    async () => {
      const {
        data: { result },
      } = await fetchEventById(id)
      return result
    },
    {
      enabled,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  )

  return event
}

export function useCreateEvent(onSuccess, onError) {
  const queryClient = useQueryClient()

  const mutationInfo = useMutation(
    async ([data]) => (await createEvent(data)).data,
    {
      onSuccess() {
        queryClient.invalidateQueries(['events'])
        queryClient.invalidateQueries(['events_overview'])
        onSuccess?.()
      },
      onError(err) {
        console.error('ERROR CREATING EVENT', err)

        onError?.(err)
      },
    },
  )

  return mutationInfo
}

export function useCreateCompetition(onSuccess, onError) {
  const queryClient = useQueryClient()

  const mutationInfo = useMutation(
    async ([data]) => (await createCompetition(data)).data,
    {
      onSuccess() {
        queryClient.invalidateQueries(['competitions'])
        onSuccess?.()
      },
      onError(err) {
        console.error('ERROR CREATING EVENT', err)

        onError?.(err)
      },
    },
  )

  return mutationInfo
}

export function useCompetitions() {
  const competitions = useQuery(
    'competitions',
    async () => {
      const {
        data: { result },
      } = await fetchCompetitions()
      return result
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  )

  return competitions
}

export function useBatsmen(id) {
  const match = useQuery(
    ['event_batsmen', id],
    async () => {
      const {
        data: { result },
      } = await fetchBatsmen(id)
      return result
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!id,
    },
  )

  return match
}

export function useEventVisibilityUpdate(onSuccess, onError) {
  const status = useMutation(
    // eslint-disable-next-line no-return-await
    async (body) => await changeEventVisibility(body),
    {
      onSuccess() {
        onSuccess?.()
      },
      onError(err) {
        console.error('ERROR UPDATING EVENT VISIBILITY', err)

        onError?.(err)
      },
    },
  )

  return status
}

export function useUpdateEvent(onSuccess, onError) {
  const queryClient = useQueryClient()

  const mutationInfo = useMutation(
    async ([id, data]) => (await updateEvent(id, data)).data,
    {
      onSuccess() {
        queryClient.invalidateQueries(['events'])
        queryClient.invalidateQueries(['events_overview'])
        onSuccess?.()
      },
      onError(err) {
        console.error('ERROR CREATING EVENT', err)

        onError?.(err)
      },
    },
  )

  return mutationInfo
}

export function useTeams() {
  const queryClient = useQueryClient()

  const mutationInfo = useMutation(
    async ([id, data]) => (await updateTeams(id, data)).data,
    {
      onSuccess() {
        queryClient.invalidateQueries(['events'])
      },
      onError(err) {
        console.error('ERROR UPDATING RATING', err)
      },
    },
  )

  return mutationInfo
}

export function useDeleteMarkets() {
  const queryClient = useQueryClient()

  const mutationInfo = useMutation(
    async ([data]) => (await deleteMarkets(data)).data,
    {
      onSuccess() {
        queryClient.invalidateQueries(['markets_event'])
      },
      onError(err) {
        console.error('ERROR UPDATING RATING', err)
      },
    },
  )

  return mutationInfo
}

export function useSuspendMarkets() {
  const mutationInfo = useMutation(
    async ([id]) => (await suspendMarkets(id)).data,
    {
      onSuccess() {},
      onError(err) {
        console.error('ERROR SUSPEND MARKETS', err)
      },
    },
  )

  return mutationInfo
}

export function useUdpateAnnouncement() {
  const mutationInfo = useMutation(
    async ([data]) => (await updateAnnouncement(data)).data,
    {
      onSuccess() {},
      onError(err) {
        console.error('ERROR UPDATE ANNOUNCEMENT', err)
      },
    },
  )

  return mutationInfo
}
