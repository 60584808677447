import axiosClient from '@services/Axios'

export async function fetchEventReports(params) {
  const {
    type,
    competition,
    date,
    dateEnd,
    partnerId,
    eventType,
    limit = 200,
    page,
  } = params

  let query = '/report/events?'

  if (type) {
    query += `type=${type}&`
  }

  if (date) {
    query += `date=${date}&`
  }

  if (dateEnd) {
    query += `date_end=${dateEnd}&`
  }

  if (competition) {
    query += `cid=${competition}&`
  }

  if (partnerId) {
    query += `partner_id=${partnerId}&`
  }

  if (eventType) {
    query += `event_type=${eventType}&`
  }

  if (page) {
    query += `page=${page}&`
  }

  query += `limit=${limit}&`

  return axiosClient.get(query)
}

export async function fetchMarketReports(id) {
  return axiosClient.get(`/report/markets/event/${id}`)
}
