import { handleActions } from 'redux-actions'
import {
  fetchLoginRequest,
  fetchLoginSuccess,
  fetchLoginError,
  logoutRequest,
  logoutSuccess,
  logoutError,
  fetchRefreshSuccess,
  fetchRefreshError,
  setTab,
  setTime,
  setRemainData,
} from '../actions/sessionActions'

const initialState = {
  token: null,
  refreshToken: null,
  user: {},
  loading: false,
  error: null,
  currentTab: 'overview',
  time: 0,
  remainData: { isRefresh: false, data: null },
}

export default handleActions(
  {
    [fetchLoginRequest]: (state) => {
      return {
        ...state,
        error: false,
        loading: true,
      }
    },

    [fetchLoginSuccess]: (state, action) => {
      return {
        ...state,
        token: action.payload.access_token,
        refreshToken: action.payload.refresh_token,
        user: action.payload.user,
        time: Date.now(),
        error: false,
        loading: false,
      }
    },

    [fetchLoginError]: (state) => {
      return {
        ...state,
        error: true,
        loading: false,
      }
    },

    [logoutRequest]: (state) => {
      return {
        ...state,
        error: false,
        loading: true,
      }
    },

    [logoutSuccess]: () => {
      return initialState
    },

    [logoutError]: (state) => {
      return {
        ...state,
        error: true,
        loading: false,
      }
    },

    [setTab]: (state, action) => {
      return {
        ...state,
        currentTab: action.payload.tab,
      }
    },

    [fetchRefreshSuccess]: (state, action) => {
      return {
        ...state,
        token: action.payload.access_token,
        refreshToken: action.payload.refresh_token,
        error: false,
        loading: false,
      }
    },

    [fetchRefreshError]: (state) => {
      return {
        ...state,
        error: true,
        loading: false,
      }
    },

    [setTime]: (state, action) => {
      return {
        ...state,
        time: action.payload.time,
      }
    },
    [setRemainData]: (state, action) => {
      return {
        ...state,
        remainData: action.payload.remainData,
      }
    },
  },
  initialState,
)
