import axiosClient from '@services/Axios'

export function fetchCurrency() {
  return axiosClient.get('/currency')
}

export function createCurrency(data) {
  return axiosClient.post('/currency', data)
}

export function fetchExchangeByCode(code) {
  return axiosClient.get(`/exchange/${code}`)
}

export function fetchExchange() {
  return axiosClient.get('/exchange')
}

export function createExchange(data) {
  return axiosClient.post('/exchange', data)
}

export function updateExchange(id, data) {
  return axiosClient.put(`/exchange/${id}`, data)
}
