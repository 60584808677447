// Session selectors

export const selectSessionData = ({ session } = {}) => session
export const selectSessionToken = (state) => selectSessionData(state)?.token
export const selectSessionRefreshToken = (state) =>
  selectSessionData(state)?.refreshToken
export const selectSessionUser = (state) => selectSessionData(state)?.user
export const selectSessionRole = (state) =>
  selectSessionData(state)?.user.role_id
export const selectSessionError = (state) => selectSessionData(state)?.error
export const selectSessionMenu = (state) => selectSessionData(state)?.options
export const selectSessionTab = (state) => selectSessionData(state)?.currentTab
export const selectSessionTime = (state) => selectSessionData(state)?.time
export const selectSessionOptions = (state) => {
  const menu = selectSessionMenu(state) ?? {}
  return Object.keys(menu)
}
export const selectSessionSubOptions = (state) => {
  const menu = selectSessionMenu(state) ?? {}
  return Object.values(menu)
}
export const selectSessionRemainData = (state) =>
  selectSessionData(state)?.remainData?.data
export const selectSessionIsRefreshData = (state) =>
  selectSessionData(state)?.remainData?.isRefresh

// Errors selectors

export const selectErrors = ({ errors } = {}) => errors
