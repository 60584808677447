import PropTypes from 'prop-types'
import classnames from 'classnames'

export default function Modal({
  isVisible,
  onClose,
  children,
  sizingClassName,
  className,
}) {
  return (
    <div
      className={classnames(
        'fixed z-20 inset-0 overflow-y-auto text-primary',
        {
          hidden: !isVisible,
        },
        className,
      )}
    >
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={() => onClose()}
        >
          <div className="absolute inset-0 bg-black opacity-10" />
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className={classnames(
            sizingClassName,
            'inline-block align-bottom bg-white rounded-3xl border border-solid text-left shadow-elevated transform transition-all sm:my-8 sm:align-middle',
          )}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          {children}
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  sizingClassName: PropTypes.string,
  className: PropTypes.string,
}

Modal.defaultProps = {
  sizingClassName: 'container sm:w-full',
  className: '',
}
