import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  fetchMarkets,
  fetchMarketById,
  fetchSelectionForecast,
  fetchMarketsByEventId,
  fetchMarketsByEventIdOverview,
  fetchMarketByCostumerId,
  fetchBackLay,
  fetchSpread,
  fetchExposureOptions,
  fetchMinMaxOptions,
  updateMarket,
  updateRating,
  updateMarketStatus,
  updateMarketStatusBookmaker,
  updateMarketStatusToSettle,
  updateRunsAndOdds,
  updateMixMax,
  updateBackLay,
  updateSpread,
  updateRuns,
  updateMarketHistoryVoid,
  addMarket,
  addMarkets,
  sendCommand,
  sendBets,
  fetchMarketHistory,
  updateMarketSequence,
  fetchMatchsTemplates,
  fetchTypesTemplates,
  fetchTemplates,
  fetchBookmakerDetails,
  updateMarketRemark,
} from '@services/Markets'

import {
  ballRunMarkets,
  suspendMarkets,
  stopBallRunMarkets,
} from '@services/Events'

export function useMarkets() {
  const markets = useQuery(
    'markets',
    async () => {
      const {
        data: { result },
      } = await fetchMarkets()
      return result
    },
    { refetchOnWindowFocus: false },
  )

  return markets
}

export function useMarketById(marketId, isEnabled) {
  const market = useQuery(
    ['market', marketId],
    async () => {
      const {
        data: { result },
      } = await fetchMarketById(marketId)
      return result
    },
    { enabled: isEnabled },
    { refetchOnWindowFocus: false },
  )

  return market
}

export function useMarketHistory(marketId) {
  const market = useQuery(
    ['market_history', marketId],
    async () => {
      const {
        data: { result },
      } = await fetchMarketHistory(marketId)
      return result
    },
    { enabled: !!marketId },
    { refetchOnWindowFocus: false },
  )

  return market
}

export function useMarketByCostumerId(marketId, isEnabled) {
  const market = useQuery(
    ['market', marketId],
    async () => {
      const {
        data: { result },
      } = await fetchMarketByCostumerId(marketId)
      return result
    },
    { enabled: isEnabled },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  )

  return market
}

export function useMarketsByEventId({ marketEventId, election, dependency }) {
  const markets = useQuery(
    ['markets_event', marketEventId],
    async () => {
      let fData

      if (election) {
        const {
          data: { result },
        } = await fetchMarketsByEventId(marketEventId, election)
        fData = result
      } else {
        const {
          data: { result },
        } = await fetchMarketsByEventId(marketEventId)
        fData = result
      }

      return fData
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: dependency,
      retry: false,
    },
  )

  return markets
}

export function useMarketsByEventIdOverview(id) {
  const markets = useQuery(
    ['markets_event_overview', id],
    async () => {
      const {
        data: { result },
      } = await fetchMarketsByEventIdOverview(id)
      return result
    },
    { refetchOnWindowFocus: false },
  )

  return markets
}

export function useBookmakerDetails(id, isEnabled) {
  const markets = useQuery(
    ['bookmaker_details', id],
    async () => {
      const {
        data: { result },
      } = await fetchBookmakerDetails(id)
      return result
    },
    {
      enabled: isEnabled,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  )

  return markets
}

export function useBackLay() {
  const backLay = useQuery(
    ['back_lay'],
    async () => {
      const {
        data: { result },
      } = await fetchBackLay()
      return result
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
    { refetchOnWindowFocus: false },
  )

  return backLay
}

export function useSpread() {
  const spread = useQuery(
    ['spread'],
    async () => {
      const {
        data: { result },
      } = await fetchSpread()
      return result
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
    { refetchOnWindowFocus: false },
  )

  return spread
}

export function useSelectionForecast() {
  const selectionForecast = useQuery(
    ['selection_forecast'],
    async () => {
      const {
        data: { result },
      } = await fetchSelectionForecast()
      return result
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
    { refetchOnWindowFocus: false },
  )

  return selectionForecast
}

export function useBackLayTest() {
  const queryClient = useQueryClient()

  const query = queryClient.getQueryData('preview')

  return query
}

export function useExposureOptions() {
  const exposure = useQuery(
    ['exposure'],
    async () => {
      const {
        data: { result },
      } = await fetchExposureOptions()
      return result
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  )

  return exposure
}

export function useMinMaxOptions() {
  const minMax = useQuery(
    ['min_max'],
    async () => {
      const {
        data: { result },
      } = await fetchMinMaxOptions()
      return result
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  )

  return minMax
}

export function useTypesTemplatesOptions() {
  const types = useQuery(
    ['types_templates'],
    async () => {
      const {
        data: { result },
      } = await fetchTypesTemplates()
      return result
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  )

  return types
}

export function useMatchTemplatesOptions() {
  const match = useQuery(
    ['match_templates'],
    async () => {
      const {
        data: { result },
      } = await fetchMatchsTemplates()
      return result
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  )

  return match
}

export function useTemplatesOptions(id) {
  const match = useQuery(
    ['templates'],
    async () => {
      const {
        data: { result },
      } = await fetchTemplates(id)
      return result
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  )

  return match
}

export function useMarketHistoryVoid() {
  const mutationInfo = useMutation(
    async ([data]) => (await updateMarketHistoryVoid(data)).data,
    {
      onSuccess() {},
      onError(err) {
        console.error('ERROR UPDATING HISTORY MARKET VOID', err)
      },
    },
  )

  return mutationInfo
}

export function useSendBets() {
  const mutationInfo = useMutation(
    async ([data]) => (await sendBets(data))?.data,
    {
      onSuccess() {},
      onError(err) {
        console.error('ERROR SEND COMMAND', err.response.data.message)
      },
    },
  )

  return mutationInfo
}

export function useSuspendedMarkets(onSuccess, onError) {
  const queryClient = useQueryClient()
  const mutationInfo = useMutation(async ([id]) => suspendMarkets(id), {
    onSuccess({ data: { result } }) {
      queryClient.invalidateQueries(['markets_event_overview', result.event_id])
      onSuccess?.()
    },
    onError(err) {
      console.error('ERROR UPDATING MARKET', err)

      onError?.(err)
    },
  })
  return mutationInfo
}

export function useBallRunMarkets(onSuccess, onError) {
  const queryClient = useQueryClient()
  const mutationInfo = useMutation(async ([id]) => ballRunMarkets(id), {
    onSuccess({ data: { result } }) {
      queryClient.invalidateQueries(['markets_event_overview', result.event_id])
      onSuccess?.()
    },
    onError(err) {
      console.error('ERROR UPDATING MARKET', err)

      onError?.(err)
    },
  })
  return mutationInfo
}

export function useStopBallRunMarkets(onSuccess, onError) {
  const queryClient = useQueryClient()
  const mutationInfo = useMutation(async ([id]) => stopBallRunMarkets(id), {
    onSuccess({ data: { result } }) {
      queryClient.invalidateQueries(['markets_event_overview', result.event_id])
      onSuccess?.()
    },
    onError(err) {
      console.error('ERROR UPDATING MARKET', err)

      onError?.(err)
    },
  })
  return mutationInfo
}

export function useMarketStatusUpdate(onSuccess, onError) {
  const queryClient = useQueryClient()

  const status = useMutation(
    // eslint-disable-next-line no-return-await
    async (body) => await updateMarketStatus(body),
    {
      onSuccess({ data: { result } }) {
        queryClient.setQueryData(
          ['markets_event', result.event_id],
          (oldData) =>
            oldData?.map((market) =>
              market.id === result.id
                ? { ...market, status: result.status }
                : market,
            ),
        )
        onSuccess?.()
      },
      onError(err) {
        console.error('ERROR UPDATING MARKET STATUS', err)

        onError?.(err)
      },
    },
  )

  return status
}

export function useMarketStatusBookmakerUpdate(onSuccess, onError) {
  const queryClient = useQueryClient()
  const status = useMutation(
    // eslint-disable-next-line no-return-await
    async ([id, body]) => await updateMarketStatusBookmaker(id, body),
    {
      async onSuccess() {
        queryClient.invalidateQueries(['bookmaker_details'])
        onSuccess?.()
      },
      onError(err) {
        console.error('ERROR UPDATING MARKET STATUS', err)

        onError?.(err)
      },
    },
  )

  return status
}

export function useMarketStatusUpdateToSettle(onSuccess, onError) {
  const queryClient = useQueryClient()
  const status = useMutation(async (body) => updateMarketStatusToSettle(body), {
    onSuccess({ data: { result } }) {
      queryClient.setQueryData(['markets_event', result.event_id], (oldData) =>
        oldData.map((market) =>
          market.id !== result.id
            ? market
            : { ...market, remark: result?.remark, status: result?.status },
        ),
      )
      onSuccess?.()
    },
    onError(err) {
      console.error('ERROR UPDATING MARKET STATUS', err)

      onError?.(err)
    },
  })

  return status
}

export function useMarketRemarkUpdate(onSuccess, onError) {
  const queryClient = useQueryClient()
  const status = useMutation(
    // eslint-disable-next-line no-return-await
    async (body) => await updateMarketRemark(body),
    {
      onSuccess({ data: { result } }) {
        queryClient.setQueryData(
          ['markets_event', result.event_id],
          (oldData) =>
            oldData.map((market) =>
              market.id !== result.id
                ? market
                : { ...market, remark: result.remark },
            ),
        )
        onSuccess?.()
      },
      onError(err) {
        console.error('ERROR UPDATING MARKET REMARK', err)

        onError?.(err)
      },
    },
  )

  return status
}

export function useAddMarket(onSuccess, onError) {
  const queryClient = useQueryClient()

  const mutationInfo = useMutation(
    async ([data]) => (await addMarket(data)).data,
    {
      onSuccess() {
        queryClient.invalidateQueries(['markets'])
        onSuccess?.()
      },
      onError(err) {
        console.error('ERROR CREATING MARKET', err)

        onError?.(err)
      },
    },
  )

  return mutationInfo
}

export function useAddMarkets(onSuccess, onError) {
  const queryClient = useQueryClient()

  const mutationInfo = useMutation(
    async ([data]) => (await addMarkets(data)).data,
    {
      onSuccess() {
        queryClient.invalidateQueries(['markets'])
        onSuccess?.()
      },
      onError(err) {
        console.error('ERROR CREATING MARKET', err)

        onError?.(err)
      },
    },
  )

  return mutationInfo
}

export function useUpdateSequence(onSuccess, onError) {
  const mutationInfo = useMutation(
    async (data) => (await updateMarketSequence(data)).data,
    {
      onSuccess() {
        onSuccess?.()
      },
      onError(err) {
        console.error('ERROR UPDATING MARKET SEQUENCE', err)

        onError?.(err)
      },
    },
  )

  return mutationInfo
}

export function useUpdateMarket() {
  const queryClient = useQueryClient()

  const mutationInfo = useMutation(
    async ([id, data]) => (await updateMarket(id, data)).data,
    {
      onSuccess() {
        queryClient.invalidateQueries(['markets'])
      },
      onError(err) {
        console.error('ERROR UPDATING RATING', err)
      },
    },
  )

  return mutationInfo
}

export function useUpdateRating() {
  const queryClient = useQueryClient()

  const mutationInfo = useMutation(
    async ([id, data]) => (await updateRating(id, data)).data,
    {
      onSuccess() {
        queryClient.invalidateQueries(['markets'])
      },
      onError(err) {
        console.error('ERROR UPDATING RATING', err)
      },
    },
  )

  return mutationInfo
}

export function useUpdateRuns() {
  const queryClient = useQueryClient()

  const mutationInfo = useMutation(
    async ([id, data]) => (await updateRuns(id, data)).data,
    {
      onSuccess() {
        queryClient.invalidateQueries(['markets'])
        queryClient.invalidateQueries(['markets_event'])
      },
      onError(err) {
        console.error('ERROR UPDATING RATING', err)
      },
    },
  )

  return mutationInfo
}

export function useUpdateRunsAndOdds(onSuccess, onError) {
  const queryClient = useQueryClient()

  let marketId = ''

  const mutationInfo = useMutation(
    async ([data]) => {
      marketId = data.marketId

      const response = await updateRunsAndOdds(data)

      return response.data
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['market', marketId])
        onSuccess?.()
      },
      onError(err) {
        console.error('ERROR CREATING EVENT', err)

        onError?.(err)
      },
    },
  )

  return mutationInfo
}

export function useUpdateMinMax(onSuccess, onError) {
  const queryClient = useQueryClient()

  let marketId = ''

  const mutationInfo = useMutation(
    async ([data]) => {
      marketId = data.marketId

      const response = await updateMixMax(data)

      return response.data
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['market', marketId])
        onSuccess?.()
      },
      onError(err) {
        console.error('ERROR CREATING EVENT', err)

        onError?.(err)
      },
    },
  )

  return mutationInfo
}

export function useUpdateSpread() {
  const queryClient = useQueryClient()

  const mutationInfo = useMutation(
    async ([id, data]) => (await updateSpread(id, data)).data,
    {
      onSuccess() {
        queryClient.invalidateQueries(['markets'])
      },
      onError(err) {
        console.error('ERROR UPDATING RATING', err)
      },
    },
  )

  return mutationInfo
}

export function useUpdateBackLay() {
  const queryClient = useQueryClient()

  const mutationInfo = useMutation(
    async ([id, data]) => (await updateBackLay(id, data)).data,
    {
      onSuccess() {
        queryClient.invalidateQueries(['market'])
      },
      onError(err) {
        console.error('ERROR UPDATING RATING', err)
      },
    },
  )

  return mutationInfo
}

export function useSendCommand(onSuccess, onError) {
  const mutationInfo = useMutation(
    async ([data]) => (await sendCommand(data)).data,
    {
      onSuccess() {
        onSuccess?.()
      },
      onError(err) {
        console.error('ERROR SEND COMMAND', err)

        onError?.(err.response.data.message)
      },
    },
  )

  return mutationInfo
}
