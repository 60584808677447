import axiosClient from '@services/Axios'

export async function fetchMarkets() {
  if (process.env.REACT_APP_USE_MOCKS === '1') {
    const { default: data } = await import('./mocked_makers.json')

    return { data }
  }

  return axiosClient.get('/markets')
}

export function fetchMarketById(id) {
  return axiosClient.get(`/markets/${id}`)
}

export function fetchMarketHistory(id) {
  return axiosClient.get(`/markets/${id}/history`)
}

export function fetchMarketByCostumerId(id) {
  return axiosClient.get(`/markets/customer/${id}`)
}

export async function fetchMarketsByEventId(id, election) {
  if (process.env.REACT_APP_USE_MOCKS === '1') {
    const { default: data } = await import('./mocked_makers.json')

    return { data }
  }

  if (election) {
    return axiosClient.get(`/markets/event/${id}?type=002`)
  }

  return axiosClient.get(`/markets/event/${id}`)
}

export async function fetchMarketsByEventIdOverview(id) {
  return axiosClient.get(`/markets/events/${id}`)
}

export function fetchBackLay() {
  return axiosClient.get(`/markets/backlay`)
}

export function fetchExposureOptions() {
  return axiosClient.get(`/markets/rating/max-exposure`)
}

export function fetchMinMaxOptions() {
  return axiosClient.get(`/markets/rating/min-max`)
}

export function fetchSpread() {
  return axiosClient.get(`/markets/spread`)
}
export function fetchSelectionForecast() {
  return axiosClient.get(`/markets/selectionforecast`)
}

export function fetchTypesTemplates() {
  return axiosClient.get(`/markets/type`)
}

export function fetchMatchsTemplates() {
  return axiosClient.get(`/markets/match`)
}

export function fetchTemplates(id) {
  return axiosClient.get(`/markets/templates/${id}`)
}

export function fetchBookmakerDetails(id) {
  return axiosClient.get(`/markets/${id}/details`)
}

export function updateMarket(id, data) {
  return axiosClient.put(`/markets/${id}`, data)
}
export function updateSpread(id, data) {
  return axiosClient.put(`/markets/${id}/spread`, data)
}

export function updateBackLay(id, data) {
  return axiosClient.put(`/markets/${id}/backlay`, data)
}

export async function updateMarketStatus(body) {
  return axiosClient.put('/markets/status', body)
}

export async function updateMarketStatusBookmaker(id, body) {
  return axiosClient.put(`/markets/${id}/details/winner`, body)
}

export async function updateMarketStatusToSettle(body) {
  return axiosClient.put('/markets/status/settle', body)
}

export async function updateMarketRemark(body) {
  return axiosClient.put('/markets/remark', body)
}

export async function updateMarketSequence(body) {
  return axiosClient.put('/markets/sequence', body)
}

export function updateRating(id, data) {
  return axiosClient.put(`/markets/${id}`, data)
}

export function updateRuns(id, data) {
  return axiosClient.put(`/markets/${id}/runs`, data)
}

export function updateRunsAndOdds(data) {
  return axiosClient.put('/markets/runsodds', data)
}

export function updateMixMax(data) {
  return axiosClient.put('/markets/minmax', data)
}

export function sendCommand(data) {
  return axiosClient.put('/markets/command', data)
}

export async function addMarket(data) {
  return axiosClient.post(`/markets`, data)
}

export async function addMarkets(data) {
  return axiosClient.post(`/markets/many`, data)
}

export async function sendBets(data) {
  return axiosClient.post(`/bets`, data)
}

export function updateMarketHistoryVoid(data) {
  return axiosClient.put('/markets/history/void', data)
}
