import React from 'react'
import { useSelector } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { selectSessionToken } from '@selectors'
import Pages from '@pages'
import useAxiosClientAuth from '@services/clientAuthHook'
import WebSocketProvider from '@services/websocket/index'

const LoginPage = React.lazy(() => import('@/pages/Login'))

const loading = (
  <div className="absolute w-full h-full bg-transparent flex justify-center items-center">
    <div className="bg-gray-300 rounded-md text-black w-32 h-32 flex justify-center items-center ">
      Loading
    </div>
  </div>
)

const queryClient = new QueryClient()

function App() {
  const token = useSelector(selectSessionToken)

  useAxiosClientAuth()
  return (
    <QueryClientProvider client={queryClient}>
      <WebSocketProvider>
        <Router>
          <React.Suspense fallback={loading}>
            <Switch>
              {token && <Pages />}
              <LoginPage />
            </Switch>
          </React.Suspense>
        </Router>
      </WebSocketProvider>
    </QueryClientProvider>
  )
}

export default App
