// eslint-disable-next-line no-unused-vars
import { useQuery, useQueryClient, useMutation } from 'react-query'
import {
  fetchCurrency,
  createCurrency,
  fetchExchange,
  fetchExchangeByCode,
  createExchange,
  updateExchange,
} from '@services/Currency'

export function useCurrency() {
  const currency = useQuery(
    'currency',
    async () => {
      const {
        data: { result },
      } = await fetchCurrency()
      return result
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  )

  return currency
}

export function useExchange() {
  const exchange = useQuery(
    'exchange',
    async () => {
      const {
        data: { result },
      } = await fetchExchange()
      return result
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  )

  return exchange
}
export function useExchangeByCode(id) {
  const exchange = useQuery(
    ['exchange_code', id],
    async () => {
      const {
        data: { result },
      } = await fetchExchangeByCode(id)
      return result
    },
    {
      refetchOnMount: false,
      enabled: !!id,
      refetchOnWindowFocus: false,
    },
  )

  return exchange
}

export function useCreateExchange(onSuccess, onError) {
  const mutationInfo = useMutation(
    async ([data]) => (await createExchange(data)).data,
    {
      onSuccess() {
        onSuccess?.()
      },
      onError(err) {
        console.error('ERROR CREATING EVENT', err)

        onError?.(err)
      },
    },
  )

  return mutationInfo
}

export function useEditExchange(onSuccess, onError) {
  const mutationInfo = useMutation(
    // eslint-disable-next-line no-return-await
    async ([data]) => await updateExchange(data.id, data),
    {
      onSuccess() {
        onSuccess?.()
      },
      onError(err) {
        console.error('ERROR CREATING EVENT', err)
        onError?.(err)
      },
    },
  )

  return mutationInfo
}

export function useCreateCurrency(onSuccess, onError) {
  const mutationInfo = useMutation(
    async ([data]) => (await createCurrency(data)).data,
    {
      onSuccess() {
        onSuccess?.()
      },
      onError(err) {
        console.error('ERROR CREATING EVENT', err)

        onError?.(err)
      },
    },
  )

  return mutationInfo
}
