import axios from 'axios'

import { oryxApiBaseUrl } from '../shared/oryx'

const axiosClient = axios.create({
  baseURL: oryxApiBaseUrl,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
})

export default axiosClient
