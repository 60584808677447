import { createAction } from 'redux-actions'
import { login } from '@services/Auth'

export const fetchLoginRequest = createAction('fetchLoginRequest')
export const fetchLoginSuccess = createAction('fetchLoginSuccess')
export const fetchLoginError = createAction('fetchLoginError')

export const fetchLogin = (username, password) => async (dispatch) => {
  dispatch(fetchLoginRequest())

  try {
    const response = await login(username, password).then((res) => res.data)
    dispatch(fetchLoginSuccess(response))
  } catch (err) {
    console.log(err)
    dispatch(fetchLoginError(err))
  }
}

export const logoutRequest = createAction('logoutRequest')
export const logoutSuccess = createAction('logoutSuccess')
export const logoutError = createAction('logoutError')

export const logoutAction = () => (dispatch) => {
  dispatch(logoutRequest())

  try {
    dispatch(logoutSuccess())
  } catch (err) {
    dispatch(logoutError())
  }
}
export const setTab = createAction('setTab')

export const fetchRefreshRequest = createAction('fetchRefreshRequest')
export const fetchRefreshSuccess = createAction('fetchRefreshSuccess')
export const fetchRefreshError = createAction('fetchRefreshError')

export const setTime = createAction('setTime')

export const setRemainData = createAction('setTime')
