import Modal from '@components/molecules/Modal'
import PropTypes from 'prop-types'

export default function ErrorModal({ message, isVisible, onClose }) {
  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      sizingClassName="w-1/3 p-10"
      className="z-30"
    >
      {message}
    </Modal>
  )
}

ErrorModal.propTypes = {
  message: PropTypes.string,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
}

ErrorModal.defaultProps = {
  message: '',
  isVisible: false,
  onClose: null,
}
