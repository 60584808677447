import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useToggle } from 'react-use'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { format, utcToZonedTime } from 'date-fns-tz'
import { FlagIcon } from '@assets/images'
import { USER_ICON, ARROW_DOWN_WHITE } from '@assets/icons'
import { setTab, logoutAction } from '@redux/actions/sessionActions'
import {
  selectSessionTab,
  selectSessionRole,
  selectSessionUser,
} from '@selectors'
import UserModal from '@components/organisms/CreateEditUserModal'
import { useUpdateUser, useRoles } from '@services/queries'
import { tabsT } from '@constants/header'
import { updateErrorStatus } from '@redux/actions/errorsActions'

export default function Header() {
  // --- Hooks -----------------------------------------------------------------
  const dispatch = useDispatch()
  const history = useHistory()
  const setActiveTab = (tab) => dispatch(setTab({ tab }))
  const [logoutTab, setLogoutTab] = useToggle(false)
  const [editUserModal, toggleEditUserModal] = useToggle(false)
  const {
    mutate: updateUser,
    isLoading: isLoadingUpdateUser,
    status: statusUpdate,
  } = useUpdateUser(null, () =>
    dispatch(
      updateErrorStatus(
        "An error has occurred when editing the user's data, please try again.",
        true,
      ),
    ),
  )
  const { data: dataRolesRaw } = useRoles()
  // --- END: Hooks ------------------------------------------------------------

  // --- Local state -----------------------------------------------------------
  const [clock, setClock] = useState(
    format(utcToZonedTime(new Date(), 'Asia/Kolkata'), 'HH:mm', {
      timeZone: 'Asia/Kolkata',
    }),
  )
  const [editUser, setEditUser] = useState(false)
  // --- END: Local state ------------------------------------------------------

  // --- Refs ------------------------------------------------------------------
  // --- END: Refs -------------------------------------------------------------

  // --- Redux -----------------------------------------------------------------
  const activeTab = useSelector(selectSessionTab)
  const role = useSelector(selectSessionRole)
  const user = useSelector(selectSessionUser)
  // --- END: Redux ------------------------------------------------------------

  // --- Side effects ----------------------------------------------------------
  useEffect(() => {
    const interval = setInterval(
      () =>
        setClock(
          format(utcToZonedTime(new Date(), 'Asia/Kolkata'), 'HH:mm', {
            timeZone: 'Asia/Kolkata',
          }),
        ),
      1000,
    )

    return setInterval(interval)
  }, [clock])

  useEffect(() => {
    if (statusUpdate === 'success') {
      toggleEditUserModal(false)
      setEditUser({})
    }
  }, [statusUpdate, toggleEditUserModal, user])

  useEffect(() => {
    if (window.location.pathname === '/market-page/general') {
      dispatch(setTab({ tab: 'crictech' }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, window.location.pathname])
  // --- END: Side effects -----------------------------------------------------

  // --- Data and handlers -----------------------------------------------------
  const tabsParser = useMemo(() => {
    const accessUsers = {
      manager: [
        'overview',
        'crictech',
        'reports',
        'user-management',
        // 'test-mode',
        'currency',
      ],
      admin: [
        'overview',
        'crictech',
        'reports',
        'user-management',
        'test-mode',
        'currency',
      ],
      trader: ['crictech'],
      partner: ['reports'],
      vcsmanager: [
        'overview',
        'crictech',
        'reports',
        'user-management',
        // 'test-mode',
        'currency',
      ],
    }

    const dataRoles = {}

    dataRolesRaw?.forEach((roleParam) => {
      dataRoles[roleParam?.name.toLocaleLowerCase().replace(' ', '')] =
        roleParam?.id
    })

    const getAccessUsers = (tab) => {
      const access = []
      Object.keys(accessUsers).forEach((userAccess) => {
        const value = accessUsers[userAccess].includes(tab)

        if (value) access.push(dataRoles?.[userAccess])
      })

      return access
    }

    return tabsT.map((tab) => ({
      ...tab,
      access: getAccessUsers(tab.tab),
    }))
  }, [dataRolesRaw])

  const onLogout = () => {
    dispatch(logoutAction())
    history.push('/login')
  }
  const changeUser = useCallback(
    async (dataUpdate) => {
      const newData = { ...dataUpdate, role_id: user?.role_id }
      await updateUser([dataUpdate?.id, newData])
    },
    [updateUser, user],
  )
  // --- END: Data and handlers ------------------------------------------------
  return (
    <div className="flex justify-between py-4 px-4 items-center z-10 bg-coquelicot sticky top-0">
      <div className="flex flex-row items-center">
        {/* {<img src} alt="Shiro logo" className="h-10" />} */}
        {tabsParser
          ?.filter((item) => item.access.includes(role))
          ?.map((tab, idx) => (
            <div
              className={`${
                activeTab === tab.tab ? 'text-white' : 'text-white opacity-60'
              } text-md font-bold ${idx === 0 ? '' : 'ml-16'}`}
              aria-hidden
              onClick={() => setActiveTab(tab.tab)}
              key={`header-button-${idx + 1}`}
            >
              <Link to={tab.url}>{tab.title}</Link>
              <div
                className={`${
                  activeTab === tab.tab
                    ? 'h-1 bg-sunshine relative -bottom-4'
                    : ''
                }`}
              />
            </div>
          ))}
      </div>
      <div className="flex flex-row">
        {/* <div className="bg-lightersmoke flex flex-row itmes-center text-xs p-1.5 h-1/2 mr-8 w-1/2 rounded-md">
          <button type="button">
            <img alt="search" src={SEARCH} className="mr-5" />
          </button>
          <input
            type="text"
            className="bg-transparent ml-2 outline-none w-full"
            placeholder="Search"
            autoComplete="on"
            onChange={null}
          />
        </div> */}
        <button
          type="button"
          className="flex flex-row items-center rounded mr-4 focus:outline-none relative"
          onClick={() => setLogoutTab()}
        >
          <img src={USER_ICON} alt="user_icon" />
          <img src={ARROW_DOWN_WHITE} alt="arrow_down" />
          <ul
            className={`${
              logoutTab ? '' : 'hidden'
            } bg-white rounded-xl absolute top-8 left-0 w-28 shadow-md`}
          >
            <div
              aria-hidden
              className="hover:bg-solitude p-4 rounded-xl w-full"
              onClick={() => {
                toggleEditUserModal(true)
                setEditUser(user)
              }}
            >
              <li className="flex flex-row items-center text-xs">
                Change Password
                <img
                  src={ARROW_DOWN_WHITE}
                  alt="arrow_down"
                  className="transform -rotate-90 ml-1"
                />
              </li>
            </div>
            <div
              aria-hidden
              className="hover:bg-solitude p-4 rounded-xl w-full"
              onClick={() => onLogout()}
            >
              <li className="flex flex-row items-center">
                Log out
                <img
                  src={ARROW_DOWN_WHITE}
                  alt="arrow_down"
                  className="transform -rotate-90 ml-1"
                />
              </li>
            </div>
          </ul>
        </button>
        <button
          type="button"
          className="items-center outline-none rounded-md p-1 hidden lg:flex"
        >
          <img
            alt="🇦🇪"
            src={FlagIcon}
            className="hidden lg:inline h-5 w-5 text-base rounded-full mr-1"
          />
          <span className="flex flex-row md:text-xs">
            <span className="text-white">{clock}</span>{' '}
            <span className="text-smoke">(GMT&nbsp;+5:30)</span>
          </span>
        </button>
      </div>
      <UserModal
        isVisible={editUserModal}
        type="password"
        userToEdit={editUser}
        onClose={() => toggleEditUserModal(false)}
        onEditClick={(e) => changeUser({ ...editUser, ...e })}
        isLoading={isLoadingUpdateUser}
      />
    </div>
  )
}
