import { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import merge from 'lodash/merge'
import { differenceInMinutes } from 'date-fns'
import {
  logoutAction,
  setTime,
  fetchRefreshError,
  fetchRefreshSuccess,
} from '@redux/actions/sessionActions'

import { selectSessionTime, selectSessionRefreshToken } from '@selectors'
import { refresh } from './Auth'

import axiosClient from './Axios'

export default function useAxiosClientAuth() {
  const { token } = useSelector((state) => state.session)
  const refreshToken = useSelector(selectSessionRefreshToken)
  const time = useSelector(selectSessionTime)
  const dispatch = useDispatch()
  const history = useHistory()
  const [clock, setClock] = useState(Date.now())

  const reFetchedData = useCallback(async () => {
    try {
      const response = await refresh(refreshToken).then((res) => res.data)
      dispatch(fetchRefreshSuccess(response))
      dispatch(setTime({ time: Date.now() }))
    } catch (error) {
      dispatch(fetchRefreshError(error))
    }
  }, [dispatch, refreshToken])

  const outSession = useCallback(async () => {
    dispatch(logoutAction())
  }, [dispatch])

  useEffect(() => {
    const interceptorResponses = axiosClient.interceptors.response.use(
      undefined,
      function axiosUnauthorizedInterceptor(err) {
        if (
          err.response.status === 401 ||
          err.response.data.message === '401 Unauthorized'
        ) {
          outSession()
          history?.push('/login')
        }
        return Promise.reject(err)
      },
    )

    const interceptorId = axiosClient.interceptors.request.use((config) => {
      if (!token) return config
      const diff = differenceInMinutes(clock, time)

      if (diff >= 50 && diff <= 59) reFetchedData()
      else if (diff >= 60) outSession()

      return merge({}, config, {
        headers: { Authorization: `Bearer ${token}` },
      })
    })

    return () => {
      axiosClient.interceptors.request.eject(interceptorId)
      axiosClient.interceptors.response.eject(interceptorResponses)
    }
  }, [clock, dispatch, history, outSession, reFetchedData, time, token])

  useEffect(() => {
    setTimeout(() => {
      setClock(Date.now())
    }, 10000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clock])
}
