import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useToggle } from 'react-use'
import UserManagementList from '@components/molecules/UserManagementList'
import Button from '@components/atoms/Button'
import UserModal from '@components/organisms/CreateEditUserModal'
import PermissionList from '@components/molecules/PermissionList'
import {
  useAddUser,
  useRoles,
  useUsers,
  useUpdateUser,
  useUpdateStatusUser,
} from '@services/queries'
import { updateErrorStatus } from '@redux/actions/errorsActions'

export default function UserManagementPage() {
  // --- Hooks -----------------------------------------------------------------
  const dispatch = useDispatch()

  const { data: usersData, isError: isUserError } = useUsers()
  const { data: rolesData, isError: isRolesError } = useRoles()
  const {
    mutate: addUser,
    isLoading: isLoadingAddUser,
    isSuccess: isSuccessAddUser,
  } = useAddUser(null, () =>
    dispatch(
      updateErrorStatus(
        "An error has occurred when add the user's data, please try again.",
        true,
      ),
    ),
  )
  const {
    mutate: updateUser,
    isLoading: isLoadingUpdateUser,
    isSuccess: isSuccessUpdateUser,
  } = useUpdateUser(null, () =>
    dispatch(
      updateErrorStatus(
        "An error has occurred when editing the user's data, please try again.",
        true,
      ),
    ),
  )
  const { mutate: updateStatusUser } = useUpdateStatusUser(null, () =>
    dispatch(
      updateErrorStatus(
        'An error has occurred when updating the user status, please try again.',
        true,
      ),
    ),
  )

  const [createUserModal, toggleCreateUserModal] = useToggle(false)
  const [editUserModal, toggleEditUserModal] = useToggle(false)
  const [permissionView, togglePermissionView] = useToggle(false)
  // --- END: Hooks ------------------------------------------------------------

  // --- Local state -----------------------------------------------------------
  const [editUser, setEditUser] = useState(null)
  // --- END: Local state ------------------------------------------------------

  // --- Refs ------------------------------------------------------------------
  // --- END: Refs -------------------------------------------------------------

  // --- Redux -----------------------------------------------------------------
  // --- END: Redux ------------------------------------------------------------

  // --- Side effects ----------------------------------------------------------
  useEffect(() => {
    let query = ''
    if (isUserError) query = "user's"
    if (isRolesError) query = 'roles'
    dispatch(
      updateErrorStatus(
        `An error has occurred when fetching the ${query} data, please try again.`,
        isUserError || isRolesError,
      ),
    )
    return () => {
      dispatch(updateErrorStatus('', false))
    }
  }, [dispatch, isUserError, isRolesError])

  useEffect(() => {
    if (isSuccessAddUser || isSuccessUpdateUser) {
      toggleCreateUserModal(false)
      toggleEditUserModal(false)
    }
  }, [
    isSuccessAddUser,
    isSuccessUpdateUser,
    toggleCreateUserModal,
    toggleEditUserModal,
  ])
  // --- END: Side effects -----------------------------------------------------

  // --- Data and handlers -----------------------------------------------------
  const createUser = useCallback(
    async (dataCreate) => {
      await addUser([dataCreate])
    },
    [addUser],
  )

  const changeUser = useCallback(
    async (dataUpdate) => {
      await updateUser([dataUpdate?.id, dataUpdate])
    },
    [updateUser],
  )

  const changeStatusUser = useCallback(
    async (dataUpdate) => {
      await updateStatusUser([dataUpdate?.id, { status: !dataUpdate?.status }])
    },
    [updateStatusUser],
  )
  // --- END: Data and handlers ------------------------------------------------
  return (
    <div
      className={`flex-1 m-4 flex flex-col shadow-lg ${
        permissionView ? 'w-2/3' : ''
      }`}
    >
      <div className="bg-white rounded-lg flex-1 py-4">
        <div className="flex flex-row justify-between">
          <div className="px-4 pb-4">
            <h3 className="text-xl uppercase">
              {permissionView ? 'Crictech Permissions' : 'Crictech Users'}
            </h3>
          </div>
          <div className="inline-flex gap-2 content-between">
            {permissionView ? (
              <Button
                buttonTitle="Return to users"
                secondary
                className="h-7 text-sm w-32 font-black mr-4"
                onClick={() => togglePermissionView(false)}
              />
            ) : (
              <>
                <Button
                  buttonTitle="View Permissions"
                  secondary
                  className="h-7 text-sm w-32 font-black"
                  onClick={() => togglePermissionView(true)}
                />
                <Button
                  buttonTitle="Add New User"
                  secondary
                  className="h-7 text-sm w-32 font-black mr-4"
                  onClick={() => toggleCreateUserModal(true)}
                />
              </>
            )}
          </div>
        </div>

        {permissionView ? (
          <div>
            <PermissionList />
          </div>
        ) : (
          <>
            <div className="flex-1">
              <UserManagementList
                data={usersData}
                onEditClick={(id) => {
                  toggleEditUserModal(true)
                  setEditUser(usersData.find((e) => e.id === id))
                }}
                onMakeInactiveClick={(id) => changeStatusUser(id)}
                rolesData={rolesData}
              />
            </div>
          </>
        )}
      </div>
      <UserModal
        isVisible={createUserModal}
        type="create"
        onClose={() => toggleCreateUserModal(false)}
        onCreateClick={(e) => createUser(e)}
        isLoading={isLoadingAddUser}
        rolesData={rolesData}
      />
      <UserModal
        isVisible={editUserModal}
        type="edit"
        userToEdit={editUser}
        onClose={() => toggleEditUserModal(false)}
        onEditClick={(e) => changeUser(e)}
        isLoading={isLoadingUpdateUser}
        rolesData={rolesData}
      />
    </div>
  )
}
