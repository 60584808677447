import { useMutation, useQuery, useQueryClient } from 'react-query'
import { fetchEventReports, fetchMarketReports } from '@services/Reports'

export function useReports({ type, date, dateEnd, competition, page, limit }) {
  const reports = useQuery(
    ['reports'],
    async () => {
      const params = {}

      if (type) params.type = type

      if (date) params.date = date

      if (dateEnd && date !== dateEnd) params.dateEnd = dateEnd

      if (competition) params.competition = competition

      if (page) params.page = page

      if (limit) params.limit = limit

      const {
        data: { result },
      } = await fetchEventReports(params)

      return result
    },
    { refetchOnWindowFocus: false, keepPreviousData: true },
  )

  return reports
}

export function useReportsSpecific(onSuccess, onError) {
  const queryClient = useQueryClient()

  const mutationInfo = useMutation(
    async ([data]) => (await fetchEventReports(data)).data,
    {
      onSuccess(data) {
        queryClient.setQueryData(['reports'], () => data?.result)
        onSuccess?.()
      },
      onError(err) {
        console.error('ERROR CREATING MARKET', err)

        onError?.(err)
      },
    },
  )

  return mutationInfo
}

export function useMarketReports(id) {
  const markets = useQuery(
    ['market_reports', id],
    async () => {
      const {
        data: { result },
      } = await fetchMarketReports(id)
      return result
    },
    { refetchOnWindowFocus: false, enabled: !!id },
  )

  return markets
}
