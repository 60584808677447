import { handleActions } from 'redux-actions'
import { changeErrorStatus } from '../actions/errorsActions'

const initialState = {
  message: '',
  isVisible: false,
}

export default handleActions(
  {
    [changeErrorStatus]: (state, action) => {
      return {
        ...state,
        message: action.payload.message,
        isVisible: action.payload.isVisible,
      }
    },
  },
  initialState,
)
