import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { selectSessionToken } from '@selectors'
import {
  createUser,
  fetchRoles,
  fetchUsers,
  updateStatusUser,
  updateUser,
} from '@services/Users'

export function useUsers(roleId) {
  const users = useQuery(
    'users',
    async () => {
      const {
        data: { result },
      } = await fetchUsers(roleId)
      return result
    },
    { refetchOnWindowFocus: false },
  )

  return users
}

export function useRoles() {
  const token = useSelector(selectSessionToken)

  const users = useQuery(
    'roles',
    async () => {
      const {
        data: { result },
      } = await fetchRoles()
      return result
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: Boolean(token),
    },
  )

  return users
}

export function useAddUser(onSuccess, onError) {
  const queryClient = useQueryClient()

  const mutationInfo = useMutation(
    async ([data]) => (await createUser(data)).data,
    {
      onSuccess() {
        queryClient.invalidateQueries(['users'])
        onSuccess?.()
      },
      onError(err) {
        console.error('ERROR CREATING USER', err)

        onError?.(err)
      },
    },
  )

  return mutationInfo
}

export function useUpdateUser(onSuccess, onError) {
  const queryClient = useQueryClient()

  const mutationInfo = useMutation(
    async ([id, data]) => (await updateUser(id, data)).data,
    {
      onSuccess() {
        queryClient.invalidateQueries(['users'])
        onSuccess?.()
      },
      onError(err) {
        console.error('ERROR UPDATING USER', err)
        onError?.(err)
      },
    },
  )

  return mutationInfo
}

export function useUpdateStatusUser(onSuccess, onError) {
  const queryClient = useQueryClient()

  const mutationInfo = useMutation(
    async ([id, data]) => (await updateStatusUser(id, data)).data,
    {
      onSuccess() {
        queryClient.invalidateQueries(['users'])
        onSuccess?.()
      },
      onError(err) {
        console.error('ERROR UPDATING STATUS USER', err)
        onError?.(err)
      },
    },
  )

  return mutationInfo
}
