import React, { useMemo, useCallback, useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import ErrorModal from '@components/molecules/ErrorModal'

import { selectSessionToken, selectSessionRole, selectErrors } from '@selectors'
import { useRoles } from '@services/queries'
import { useDispatch, useSelector } from 'react-redux'
import { updateErrorStatus } from '@redux/actions/errorsActions'
import Layout from '@layouts'

import UserManagementPage from './UserManagementPage'

const MarketPage = React.lazy(() => import('./MarketPage'))
const Reports = React.lazy(() => import('./Reports'))
const Overview = React.lazy(() => import('./Overview'))
const Currency = React.lazy(() => import('./Currency'))
const TestMode = React.lazy(() => import('./TestBet'))

export default function Pages() {
  // --- Hooks -----------------------------------------------------------------
  const { data: rolesData, isError: isRolesError } = useRoles()
  // --- END: Hooks ------------------------------------------------------------

  // --- Local state -----------------------------------------------------------
  // --- END: Local state ------------------------------------------------------

  // --- Refs ------------------------------------------------------------------
  // --- END: Refs -------------------------------------------------------------

  // --- Redux -----------------------------------------------------------------
  const token = useSelector(selectSessionToken)
  const role = useSelector(selectSessionRole)
  const dispatch = useDispatch()
  const errors = useSelector(selectErrors)
  // --- END: Redux ------------------------------------------------------------

  // --- Side effects ----------------------------------------------------------
  useEffect(() => {
    let query = ''
    if (isRolesError) query = 'roles'
    dispatch(
      updateErrorStatus(
        `An error has occurred when fetching the ${query} data, please try again.`,
        isRolesError,
      ),
    )
    return () => {
      dispatch(updateErrorStatus('', false))
    }
  }, [dispatch, isRolesError])
  // --- END: Side effects -----------------------------------------------------

  // --- Data and handlers -----------------------------------------------------
  const roles = useMemo(() => {
    const temp = {}
    rolesData?.forEach(({ name, id }) => {
      temp[name.toLocaleLowerCase().replace(' ', '')] = id
    })
    return temp
  }, [rolesData])

  const hideError = useCallback(() => {
    dispatch(updateErrorStatus('', false))
  }, [dispatch])
  // --- END: Data and handlers ------------------------------------------------

  return (
    <Layout>
      <ErrorModal
        isVisible={errors.isVisible}
        onClose={() => hideError()}
        message={errors.message}
      />
      <Switch>
        {[roles.trader, roles.manager, roles.admin, roles.vcsmanager].includes(
          role,
        ) && (
          <>
            <Route
              key="route-item-market-page"
              path="/market-page"
              name="Market Page"
              component={MarketPage}
            />
            {[roles.manager, roles.admin, roles.vcsmanager].includes(role) && (
              <>
                <Route
                  key="route-item-reports-page"
                  path="/reports"
                  name="Reports"
                  component={Reports}
                />
                <Route
                  key="route-item-user-management-page"
                  path="/user-management"
                  name="user-management"
                  component={UserManagementPage}
                />
                <Route
                  key="route-item-overview"
                  path="/overview"
                  name="Overview"
                  component={Overview}
                />
                <Route
                  key="route-item-currency"
                  path="/currency"
                  name="Currency"
                  component={Currency}
                />
              </>
            )}
            {[roles.admin].includes(role) && (
              <>
                <Route
                  key="route-item-test-mode"
                  path="/test-mode"
                  name="Test Mode"
                  component={TestMode}
                />
              </>
            )}
            {![roles.admin].includes(role) && (
              <Route exact path="/test-mode">
                {token ? <Redirect to="/overview" /> : <Redirect to="/login" />}
              </Route>
            )}
            {![roles.trader].includes(role) && (
              <Route exact path="/">
                {token ? <Redirect to="/overview" /> : <Redirect to="/login" />}
              </Route>
            )}
            {[roles.trader].includes(role) && (
              <Route exact path="/overview">
                {token ? (
                  <Redirect to="/market-page/general" />
                ) : (
                  <Redirect to="/login" />
                )}
              </Route>
            )}
          </>
        )}
        {[roles.partner].includes(role) && (
          <>
            <Route
              key="route-item-reports-page"
              path="/reports"
              name="Reports"
              component={Reports}
            />
          </>
        )}
      </Switch>
    </Layout>
  )
}
