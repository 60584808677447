import axiosClient from '@services/Axios'

export async function fetchEvents({ election, virtual, date, dateEnd, limit }) {
  let query = '/events?'

  if (date && dateEnd && !election) {
    query += `date=${date}&date_end=${dateEnd}&`
  } else if (date && virtual) {
    query += `type=003&date=${date}`
  } else if (date && !election) {
    query += `date=${date}&`
  } else if (date && election) {
    query += `type=002&`
  }

  if (limit) query += `limit=${limit}`

  return axiosClient.get(query)
}

export async function fetchEventById(id) {
  return axiosClient.get(`/events/${id}`)
}

export function createEvent(data) {
  return axiosClient.post(`/events`, data)
}

export function createCompetition(data) {
  return axiosClient.post('/competitions', data)
}

export function fetchCompetitions() {
  return axiosClient.get('/competitions')
}

export function fetchBatsmen(id) {
  return axiosClient.get(`/events/players/${id}`)
}

export async function changeEventVisibility(data) {
  return axiosClient.put('/events/visibility', data)
}

export function updateEvent(id, data) {
  return axiosClient.put(`/events/${id}`, data)
}

export function updateTeams(id, data) {
  return axiosClient.put(`/events/teams/${id}`, data)
}

export function updateBatsmen(id, data) {
  return axiosClient.put(`/events/players/${id}`, data)
}

export async function deleteMarkets(data) {
  return axiosClient.delete(`/markets/many`, {
    data,
  })
}

export function suspendMarkets(id) {
  return axiosClient.put(`/events/${id}/suspend`)
}

export function ballRunMarkets(id) {
  return axiosClient.put(`/events/${id}/ballrun`)
}

export function stopBallRunMarkets(id) {
  return axiosClient.put(`/events/${id}/ballrunstar`)
}

export function updateAnnouncement(data) {
  return axiosClient.put(`/events/announce`, data)
}
