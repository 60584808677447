export const tabsT = [
  {
    title: 'Overview',
    tab: 'overview',
    url: '/overview',
  },
  {
    title: 'Event Manager',
    tab: 'crictech',
    url: '/market-page/general',
  },
  {
    title: 'Reports',
    tab: 'reports',
    url: '/reports',
  },
  {
    title: 'User Management',
    tab: 'user-management',
    url: '/user-management',
  },
  {
    title: 'Test Mode',
    tab: 'test-mode',
    url: '/test-mode',
  },
  {
    title: 'Currency',
    tab: 'currency',
    url: '/currency',
  },
]
