import React from 'react'
import PropTypes from 'prop-types'

export default function Button({
  onClick,
  buttonTitle,
  className,
  disabled,
  allowFocus,
  secondary,
  alternType,
}) {
  return (
    <button
      aria-hidden
      type={alternType ? 'button' : 'submit'}
      onClick={onClick}
      className={`flex-1 py-1 px-2 rounded-md 
        ${
          allowFocus
            ? 'focus:text-coquelicot focus:border focus:border-coquelicot focus:bg-transparent'
            : ''
        }
        focus:font-semibold
        ${
          secondary
            ? 'text-coquelicot border border-coquelicot bg-transparent'
            : 'bg-coquelicot text-white border border-coquelicot outline-none'
        }
        disabled:text-coquelicot disabled:border disabled:border-coquelicot disabled:bg-transparent disabled:font-semibold
        focus:outline-none
        font-semibold ${className}`}
      disabled={disabled}
    >
      {buttonTitle}
    </button>
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  buttonTitle: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  allowFocus: PropTypes.bool,
  secondary: PropTypes.bool,
  alternType: PropTypes.bool,
}

Button.defaultProps = {
  onClick: null,
  buttonTitle: null,
  className: '',
  disabled: false,
  allowFocus: true,
  secondary: false,
  alternType: false,
}
