export const getHeader = (value) => {
  const responses = {
    create: 'Add User',
    edit: 'Edit User',
    password: 'Change Password',
  }

  return responses[value]
}

export const onButtonText = (typeButton) => {
  if (typeButton === 'create') return 'Create'
  if (typeButton === 'edit') return 'Edit'
  return 'Update'
}
