import React, { useCallback, useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import Select from 'react-select'
import { useToggle } from 'react-use'
import Modal from '@components/molecules/Modal'
import Loader from '@components/atoms/Loader'
import { PASSWORD } from '@assets/icons'
import { customStyles } from '@styles/createEditUserStyles'
import { getHeader, onButtonText } from '@utils/createEditUser'

export default function CreateEditUser({
  isVisible,
  type,
  userToEdit,
  onClose,
  onCreateClick,
  onEditClick,
  errorUser,
  isLoading,
  rolesData,
}) {
  // --- Hooks -----------------------------------------------------------------
  const { register, handleSubmit, errors, setError, reset, clearErrors } =
    useForm({
      mode: 'onBlur',
    })
  const [password, togglePassword] = useToggle(true)
  const [confirm, toggleConfirm] = useToggle(true)
  // --- END: Hooks ------------------------------------------------------------

  // --- Local state -----------------------------------------------------------
  const [role, setRole] = useState({})
  // --- END: Local state ------------------------------------------------------

  // --- Refs ------------------------------------------------------------------
  // --- END: Refs -------------------------------------------------------------

  // --- Redux -----------------------------------------------------------------
  // --- END: Redux ------------------------------------------------------------

  // --- Data and handlers -----------------------------------------------------
  const roles = useMemo(() => {
    return rolesData?.map(({ name, id }) => ({
      label: name,
      value: id,
    }))
  }, [rolesData])

  const submitHandler = useCallback(
    async (data) => {
      if (data.confirm_password !== data.password) {
        setError('password', {
          type: 'same',
          message: 'Both password must be the same',
        })
        setError('confirm_password', {
          type: 'same',
          message: 'Both password must be the same',
        })
      } else if (
        type === 'password' &&
        (!data.confirm_password || !data.password)
      ) {
        setError('password', {
          type: 'empty',
          message: 'You must set new password',
        })
        setError('confirm_password', {
          type: 'empty',
          message: 'You must set new password',
        })
      } else if (type === 'edit' || type === 'password') {
        onEditClick({
          ...data,
          role_id: role?.value,
          id: userToEdit.id,
          password: data.password === '' ? undefined : data.password,
          confirm_password:
            data.confirm_password === '' ? undefined : data.confirm_password,
        })
      } else {
        onCreateClick({
          ...data,
          role_id: role.value,
          confirm_password: undefined,
        })
      }
    },
    [onCreateClick, onEditClick, role?.value, setError, type, userToEdit?.id],
  )
  // --- END: Data and handlers ------------------------------------------------

  // --- Side effects ----------------------------------------------------------
  useEffect(() => {
    if (!isVisible) reset()
  }, [isVisible, reset])

  useEffect(() => {
    reset()
    if (type === 'edit' || type === 'password') {
      setRole(roles?.find((e) => e.value === userToEdit?.role_id))
    }
  }, [reset, roles, type, userToEdit])
  // --- END: Side effects -----------------------------------------------------

  return (
    <>
      <Modal
        isVisible={isVisible}
        onClose={onClose}
        sizingClassName="max-w-screen-xs w-1/3"
      >
        <div className="py-4 flex flex-col gap-5">
          <h1 className="text-black text-lg font-medium text-center uppercase">
            {getHeader(type)}
          </h1>
          <form
            onSubmit={handleSubmit(submitHandler)}
            className="flex flex-col gap-3 mx-4"
          >
            {type !== 'password' && (
              <>
                <div className="flex flex-col gap-3">
                  <label htmlFor="market_type" className="text-lg">
                    First name
                  </label>
                  <input
                    type="text"
                    name="first_name"
                    autoComplete="on"
                    className={`flex-1 ${
                      errors?.first_name ? 'border-red-500' : 'border-gray-200'
                    } border p-2 rounded-lg`}
                    ref={register({
                      required: true,
                    })}
                    defaultValue={type === 'edit' ? userToEdit?.first_name : ''}
                  />
                </div>
                <div className="flex flex-col gap-3">
                  <label htmlFor="market_type" className="text-lg">
                    Last name
                  </label>
                  <input
                    type="text"
                    name="last_name"
                    autoComplete="on"
                    className={`flex-1 ${
                      errors?.last_name ? 'border-red-500' : 'border-gray-200'
                    } border p-2 rounded-lg`}
                    ref={register({
                      required: true,
                    })}
                    defaultValue={type === 'edit' ? userToEdit?.last_name : ''}
                  />
                </div>
                <div className="flex flex-col gap-3">
                  <label htmlFor="market_type" className="text-lg">
                    Username
                  </label>
                  <input
                    type="text"
                    name="username"
                    autoComplete="on"
                    className={`flex-1 ${
                      errors?.username ? 'border-red-500' : 'border-gray-200'
                    } border p-2 rounded-lg`}
                    ref={register({
                      required: true,
                    })}
                    defaultValue={type === 'edit' ? userToEdit?.username : ''}
                  />
                </div>
                <div className="flex flex-col gap-3">
                  <span className="text-lg">Role</span>
                  <Select
                    options={roles}
                    styles={customStyles}
                    onChange={(v) => setRole(v)}
                    defaultValue={role}
                    value={role}
                  />
                </div>
              </>
            )}
            <div className="flex flex-col gap-3">
              <label htmlFor="market_type" className="text-lg">
                Set Password
              </label>
              <div
                className={`flex flex-row ${
                  errors?.password ? 'border-red-500' : 'border-gray-200'
                } border p-2 rounded-lg`}
              >
                <input
                  type={password ? 'password' : 'text'}
                  name="password"
                  autoComplete="on"
                  className="flex-1"
                  ref={
                    type === 'create'
                      ? register({
                          required: true,
                          minLength: 6,
                        })
                      : register()
                  }
                />
                <button type="button" onClick={togglePassword}>
                  <img alt="password" src={PASSWORD} />
                </button>
              </div>
            </div>
            <div className="flex flex-1 justify-center">
              <span className="text-coquelicot">
                {errors?.password?.type === 'minLength' &&
                  'Password must have at least 6 characters'}
                {errors?.password?.message}
              </span>
            </div>
            <div className="flex flex-col gap-3">
              <label htmlFor="market_type" className="text-lg">
                Confirm New Password
              </label>
              <div
                className={`flex flex-row ${
                  errors?.confirm_password
                    ? 'border-red-500'
                    : 'border-gray-200'
                } border p-2 rounded-lg`}
              >
                <input
                  type={confirm ? 'password' : 'text'}
                  name="confirm_password"
                  autoComplete="on"
                  className="flex-1"
                  ref={
                    type === 'create'
                      ? register({
                          required: true,
                          minLength: 6,
                        })
                      : register()
                  }
                />
                <button type="button" onClick={toggleConfirm}>
                  <img alt="password" src={PASSWORD} />
                </button>
              </div>
            </div>
            <div className="flex flex-1 justify-center">
              <span className="text-coquelicot">
                {errors?.confirm_password?.type === 'minLength' &&
                  'Password must have at least 6 characters'}
                {errors?.confirm_password?.message}
              </span>
            </div>
            <div className="flex flex-1 justify-center">
              <span className="text-coquelicot">{errorUser}</span>
            </div>
            <div className="grid grid-cols-4 gap-5 px-8">
              <button
                type="button"
                className="col-span-2 text-coquelicot border border-solid border-coquelicot rounded-md py-1"
                onClick={() => {
                  reset()
                  clearErrors()
                  onClose()
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="col-span-2  bg-coquelicot text-white border border-solid border-coquelicot rounded-md py-1"
              >
                {isLoading ? <Loader /> : onButtonText(type)}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  )
}

CreateEditUser.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  userToEdit: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onCreateClick: PropTypes.func,
  errorUser: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  rolesData: PropTypes.node,
}

CreateEditUser.defaultProps = {
  userToEdit: null,
  onCreateClick: () => {},
  errorUser: '',
  rolesData: null,
}
