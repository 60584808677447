import axiosClient from '@services/Axios'
import axiosRefresh from '@services/AxiosRefresh'

export function register(name, username, password, roleId) {
  const data = {
    name,
    username,
    password,
    role_id: roleId,
  }

  return axiosClient.post('/players', data)
}

export function login(username, password) {
  const data = { username, password }

  return axiosClient.post('/login', data)
}

export function refresh(refreshToken) {
  const data = {
    headers: { Authorization: `Bearer ${refreshToken}` },
  }

  return axiosRefresh.get('/refresh', { ...data })
}
