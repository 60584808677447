/* eslint-disable react/prop-types */
import { createContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'
import merge from 'lodash/merge'
import { format } from 'date-fns'
import { oryxSocketBaseUrl } from '@shared/oryx'
import { selectSessionUser } from '../../providers/selectors'

export const WebSocketContext = createContext(null)

export default ({ children }) => {
  const queryClient = useQueryClient()

  const [ws, setWs] = useState(null)

  const userId = useSelector((s) => selectSessionUser(s)?.id)

  useEffect(() => {
    if (!userId) return () => {}

    let closed = false
    let socket

    const connect = () => {
      if (closed) return

      socket = new WebSocket(`${oryxSocketBaseUrl}/ws/${userId}`)

      socket.addEventListener('message', (e) => {
        const payload = JSON.parse(e.data)
        console.debug('payload', payload)

        if (payload !== null) {
          const { source = '', type, data } = payload
          const date = format(
            type === 'event'
              ? new Date(data.started_at.slice(0, 19))
              : new Date(),
            'yyyy-MM-dd',
          )

          const dateStatus = format(
            source === 'status-event'
              ? new Date(data.started_at.slice(0, 19))
              : new Date(),
            'yyyy-MM-dd',
          )
          switch (source) {
            case 'market-status':
              switch (type) {
                case 'add':
                  queryClient.setQueryData(
                    ['markets_event', data.event_id],
                    (oldData) =>
                      oldData
                        ? oldData.map((market) =>
                            market.id !== data.id ? market : data,
                          )
                        : [data],
                  )

                  break
                case 'delete':
                  queryClient.setQueryData(
                    ['markets_event', data.event_id],
                    (oldData) =>
                      oldData
                        ? oldData.filter((market) => market.id !== data.id)
                        : [],
                  )
                  break
                case 'update':
                  queryClient.setQueryData(
                    ['markets_event', data.event_id],
                    (oldData) =>
                      oldData
                        ? oldData.map((market) =>
                            market.id !== data.id
                              ? market
                              : { ...market, ...data },
                          )
                        : [data],
                  )
                  break
                case 'update-many':
                  queryClient.invalidateQueries([
                    'markets_event',
                    data.event_id,
                  ])
                  break
                default:
                  console.error(`Uknown operation for ${source} event: ${type}`)
                  break
              }
              break

            case 'market':
              switch (type) {
                case 'add':
                  queryClient.invalidateQueries(['markets_event'])
                  break
                case 'delete':
                  queryClient.invalidateQueries(['markets_event'])
                  break
                case 'update':
                  if (
                    queryClient.getQueryData(['markets_event', data.event_id])
                  ) {
                    queryClient.setQueryData(
                      ['markets_event', data.event_id],
                      (oldData) =>
                        oldData.map((market) =>
                          market.id !== data.id
                            ? market
                            : { ...market, ...data },
                        ),
                    )
                  }
                  break
                case 'add-many':
                  /* queryClient.setQueryData(['markets_event', data], (oldData) =>
                    oldData.map((market) =>
                      market.id !== data.id ? market : data,
                    ),
                  ) */
                  queryClient.invalidateQueries(['markets_event'])
                  break
                default:
                  console.error(`Uknown operation for ${source} event: ${type}`)
                  queryClient.invalidateQueries(['markets_event'])
                  break
              }

              break

            case 'bets_exposure':
              switch (type) {
                case 'update':
                  queryClient.invalidateQueries(['market'])
                  break
                default:
                  console.error(`Uknown operation for ${source} event: ${type}`)
                  queryClient.invalidateQueries(['markets_event'])
                  break
              }

              break

            case 'event':
              // TODO: To put specific date to 'events' query in order to invalidate query
              switch (type) {
                case 'add':
                  queryClient.invalidateQueries(['events'])
                  queryClient.invalidateQueries(['events_overview'])
                  break
                case 'delete':
                  queryClient.setQueryData(['events', date], (oldData) =>
                    oldData.filter((event) => event.id !== data.id),
                  )
                  break
                case 'update':
                  queryClient.invalidateQueries(['events'])
                  queryClient.invalidateQueries(['events_overview'])
                  queryClient.invalidateQueries(['markets_event', data.id])
                  break
                default:
                  console.error(`Uknown operation for ${source} event: ${type}`)
                  break
              }

              break

            case 'market-details':
              switch (type) {
                case 'update':
                  queryClient.setQueryData(
                    ['markets_event', data.event_id],
                    (oldData) =>
                      oldData
                        ? oldData.map((market) =>
                            market.id !== data.id ? market : data,
                          )
                        : [data],
                  )

                  queryClient.setQueryData(['market', data.id], (oldData) =>
                    merge({}, oldData, data),
                  )
                  break
                default:
                  console.error(`Uknown operation for ${source} event: ${type}`)
                  break
              }
              break

            case 'preview':
              switch (type) {
                case 'update':
                  queryClient.setQueryData(['preview'], (oldData) =>
                    merge({}, oldData, data),
                  )
                  break
                default:
                  console.error(`Uknown operation for ${source} event: ${type}`)
                  break
              }
              break

            case 'market-spread':
              switch (type) {
                case 'update':
                  queryClient.setQueryData(['market', data.id], (oldData) =>
                    merge({}, oldData, data),
                  )
                  break
                default:
                  console.error(`Uknown operation for ${source} event: ${type}`)
                  break
              }
              break

            case 'market-back-lay':
              switch (type) {
                case 'update':
                  queryClient.setQueryData(['market', data.id], (oldData) =>
                    merge({}, oldData, data),
                  )
                  break
                default:
                  console.error(`Uknown operation for ${source} event: ${type}`)
                  break
              }
              break
            // TODO: To put the creation date of the event which status must be updated
            case 'status-event':
              switch (type) {
                case 'update':
                  queryClient.invalidateQueries(['events', dateStatus])
                  break
                default:
                  console.error(`Uknown operation for ${source} event: ${type}`)
                  break
              }
              break

            case 'market-sequence':
              switch (type) {
                case 'update':
                  if (
                    queryClient.getQueryData([
                      'markets_event',
                      data[0].event_id,
                    ])
                  ) {
                    queryClient.setQueryData(
                      ['markets_event', data[0].event_id],
                      (oldData) => {
                        const newData = oldData
                        for (let i = 0; i < oldData.length; i += 1) {
                          const obj = data.find((el) => el.id === oldData[i].id)
                          if (obj) {
                            newData[i].sequence = obj.sequence
                          }
                        }
                        const sorted = newData.sort(
                          (a, b) => a.sequence - b.sequence,
                        )
                        return sorted
                      },
                    )
                  }
                  break
                default:
                  console.error(`Uknown operation for ${source} event: ${type}`)
                  break
              }
              break

            case 'history':
              switch (type) {
                case 'update':
                  if (
                    queryClient.getQueryData(['market_history', data.market_id])
                  ) {
                    queryClient.setQueryData(
                      ['market_history', data.market_id],
                      () => data?.run_odds_history,
                    )
                  }
                  break
                default:
                  console.error(`Uknown operation for ${source} event: ${type}`)
                  break
              }
              break

            case 'event-announce':
              switch (type) {
                case 'update':
                  if (
                    queryClient.getQueryData([
                      'events',
                      format(new Date(data.started_at), 'yyyy-MM-dd'),
                    ])
                  ) {
                    queryClient.setQueryData(
                      [
                        'events',
                        format(new Date(data.started_at), 'yyyy-MM-dd'),
                      ],
                      (oldData) =>
                        oldData.map((val) =>
                          val.id === data.id ? { ...val, ...data } : val,
                        ),
                    )
                  }
                  break
                default:
                  console.error(`Uknown operation for ${source} event: ${type}`)
                  break
              }
              break

            case 'markets-sequence':
              switch (type) {
                case 'update':
                  queryClient.invalidateQueries([
                    'markets_event',
                    data.event_id,
                  ])
                  break
                default:
                  console.error(`Uknown operation for ${source} event: ${type}`)
                  break
              }
              break

            case 'currency':
              switch (type) {
                case 'add':
                  queryClient.invalidateQueries(['currency'])
                  break
                case 'update':
                  queryClient.setQueryData(
                    ['exchange_code', data.code],
                    (oldData) =>
                      oldData?.map((val) => (val.id === data.id ? data : val)),
                  )
                  break
                default:
                  console.error(`Uknown operation for ${source} event: ${type}`)
                  break
              }
              break

            case 'exchange-rate':
              switch (type) {
                case 'add':
                  queryClient.invalidateQueries(['exchange_code'])
                  break
                default:
                  console.error(`Uknown operation for ${source} event: ${type}`)
                  break
              }
              break

            case 'event-visibility':
              switch (type) {
                case 'update':
                  queryClient.invalidateQueries(['events'])
                  queryClient.invalidateQueries(['events_overview'])
                  break
                default:
                  console.error(`Uknown operation for ${source} event: ${type}`)
                  break
              }
              break
            default:
              console.error(`Uknown event: ${source}`)

              queryClient.invalidateQueries('markets_event')
              break
          }
        }
      })

      setWs({ socket })

      socket.addEventListener('close', connect)
    }

    connect()

    return () => {
      closed = true
      socket.close()
    }
  }, [userId, queryClient])

  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  )
}
