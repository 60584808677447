export const customStyles = {
  menu: (provided) => ({
    ...provided,
    borderRadius: '1rem',
    overflow: 'hidden',
    margin: '0',
  }),
  option: (provided, state) => ({
    ...provided,
    color: 'black',
    backgroundColor: state.isFocused
      ? 'rgba(196, 196, 196, 0.3)'
      : 'transparent',
    textAlign: 'center',
  }),
  control: (provided) => ({
    ...provided,
    borderColor: 'rgba(196, 196, 196, 0.3)',
    textAlign: 'center',
    cursor: 'pointer',
  }),
  indicatorSeparator: () => {
    return {
      display: 'none',
    }
  },
  indicatorsContainer: (provided) => {
    return {
      ...provided,
      position: 'absolute',
      top: '16px',
      right: '10px',
      color: 'blue',
      width: '0',
      height: '0',
      border: '6px solid transparent',
      borderColor: '#3F8ECC transparent transparent transparent',
    }
  },
  dropdownIndicator: () => {
    return {
      display: 'none',
    }
  },
}
