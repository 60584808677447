import PropTypes from 'prop-types'
import classnames from 'classnames'
import { format } from 'date-fns'
import _isFunction from 'lodash/isFunction'
import { Popover } from 'react-tiny-popover'
import _merge from 'lodash/merge'
import { useReducer, useMemo } from 'react'

import { PENCIL } from '@assets/icons'

const cols = [
  { label: 'Username', key: 'username', className: '' },
  { label: 'First Name', key: 'first_name', className: '' },
  { label: 'Last Name', key: 'last_name', className: '' },
  { label: 'Role', key: 'role_id', className: '' },
  { label: 'Date Added', key: 'created_at', className: '' },
  { label: 'Last Logged In', key: 'updated_at', className: '' },
  {
    label: 'Status',
    key: 'status',
    className: ({ status }) =>
      status ? 'text-slimy-green' : 'text-coquelicot',
  },
]

const headerCellClass =
  'font-black uppercase border-b border-solid pb-0 flex flex-row gap-2'
const firstHeaderCellClass = '-ml-4 pl-10'
const lastHeaderCellClass = '-mr-4 pr-10'

const cellClass = 'py-2 px-2'
const firstCellClass = 'pl-6'
const lastCellClass = 'pr-6'
const oddCellClass = 'bg-cultured'

function EditUserPopover({
  isOpen,
  toggleIsOpen,
  onEditClick,
  onMakeInactiveClick,
  status,
}) {
  return (
    <Popover
      positions={['left', 'top', 'bottom', 'right']}
      isOpen={isOpen}
      onClickOutside={() => toggleIsOpen(false)}
      containerClassName="h-18"
      content={
        <div className="bg-white flex flex-col py-4 rounded-lg">
          <button
            type="button"
            onClick={() => {
              onEditClick()
              toggleIsOpen(false)
            }}
            className="px-3 py-1 focus:outline-none bg-cultured"
          >
            Edit user details
          </button>
          <button
            type="button"
            onClick={() => {
              onMakeInactiveClick()
              toggleIsOpen(false)
            }}
            className={`px-3 py-1 focus:outline-none ${
              status ? 'text-coquelicot' : 'text-slimy-green'
            }`}
          >
            {status ? 'Make inactive' : 'Make active'}
          </button>
        </div>
      }
    >
      <button
        type="button"
        className="focus:outline-none"
        onClick={() => toggleIsOpen()}
      >
        <img alt="edit" src={PENCIL} />
      </button>
    </Popover>
  )
}
EditUserPopover.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleIsOpen: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onMakeInactiveClick: PropTypes.func.isRequired,
  status: PropTypes.bool.isRequired,
}

export default function UserManagementList({
  data,
  onEditClick,
  onMakeInactiveClick,
  rolesData,
}) {
  // --- Hooks -----------------------------------------------------------------
  // --- END: Hooks ------------------------------------------------------------

  // --- Local state -----------------------------------------------------------
  // --- END: Local state ------------------------------------------------------

  // --- Refs ------------------------------------------------------------------
  // --- END: Refs -------------------------------------------------------------

  // --- Redux -----------------------------------------------------------------
  const [isCellPopoverOpen, toggleIsCellPopoverOpen] = useReducer(
    (s, [id, val]) => _merge({}, s, { [id]: val ?? !s[id] }),
    {},
  )
  // --- END: Redux ------------------------------------------------------------

  // --- Side effects ----------------------------------------------------------
  // --- END: Side effects -----------------------------------------------------

  // --- Data and handlers -----------------------------------------------------
  const roles = useMemo(() => {
    const temp = {}
    rolesData?.forEach(({ name, id }) => {
      temp[id] = name
    })
    return temp
  }, [rolesData])

  const getValue = (row, key) => {
    if (key === 'role_id') {
      return roles[row.role_id]
    }
    if (key === 'created_at' || key === 'updated_at') {
      return format(new Date(row[key]), 'yyyy-dd-MM  HH:mm')
    }
    if (key === 'status') {
      return row[key] ? 'active' : 'inactive'
    }
    return row[key]
  }
  // --- END: Data and handlers ------------------------------------------------

  return (
    <div
      className="grid px-4"
      style={{
        gridTemplateColumns: `repeat(${cols.length}, minmax(0, 1fr)) minmax(0, auto)`,
      }}
    >
      {/* Headers */}
      {cols.map(({ label }, idx) => (
        <div
          key={label}
          className={classnames(cellClass, headerCellClass, {
            [firstCellClass]: !idx,
            [firstHeaderCellClass]: !idx,
          })}
        >
          <span>{label}</span>
        </div>
      ))}
      <div
        className={classnames(cellClass, headerCellClass, lastHeaderCellClass)}
      />

      {/* Rows */}
      {data?.map((row, rowIdx) =>
        cols
          .map(({ key, className: colClassName }, idx) => (
            <div
              className={classnames(
                cellClass,
                _isFunction(colClassName)
                  ? colClassName(row, idx)
                  : colClassName,
                { [oddCellClass]: rowIdx % 2, [firstCellClass]: !idx },
              )}
              key={`${row.id}-${key}`}
            >
              {getValue(row, key)}
            </div>
          ))
          .concat(
            <div
              className={classnames(cellClass, lastCellClass, {
                [oddCellClass]: rowIdx % 2,
              })}
              key={`${row.id}-edit`}
            >
              <EditUserPopover
                isOpen={!!isCellPopoverOpen[row.id]}
                toggleIsOpen={(val) => toggleIsCellPopoverOpen([row.id, val])}
                onEditClick={() => onEditClick(row.id)}
                onMakeInactiveClick={() =>
                  onMakeInactiveClick({ id: row.id, status: row.status })
                }
                status={row.status}
              />
            </div>,
          ),
      )}
    </div>
  )
}

UserManagementList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape(
      Object.assign(
        {},
        { id: PropTypes.string.isRequired },
        ...cols.map(({ key }) => ({ [key]: PropTypes.node })),
      ),
    ),
  ).isRequired,
  onEditClick: PropTypes.func.isRequired,
  onMakeInactiveClick: PropTypes.func.isRequired,
  rolesData: PropTypes.node.isRequired,
}
